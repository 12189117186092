import React from 'react';

import { FCWithChildren } from '@wix/challenges-web-library';
import { Box } from '../../../../../../components-shared/Box';
import { ButtonSize as ButtonSIZE } from 'wix-ui-tpa/cssVars';
import {
  ChevronLeftSmall,
  ChevronRightSmall,
} from '@wix/wix-ui-icons-common/on-stage';
import {
  useEnvironment,
  useExperiments,
  useTranslation,
} from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/tpa-settings/react';
import { useSections } from '../../../../../../contexts/ParticipantSections/ParticipantSectionsContext';
import { useParticipantSteps } from '../../../../../../contexts/ParticipantStepsDataProvider/ParticipantStepsContext';
import { useResolveStep } from '../../../../../../contexts/ResolveStep/ResolveStepContext';
import { sidebarLayoutDataHooks } from '../../../views/SidebarLayout/sidebarLayoutDataHooks';
import { SidebarLayoutButton } from '../../Buttons/SidebarLayoutButton/SidebarLayoutButton';
import utils, {
  ChallengeEntityType,
  IFollowingEntity,
} from '../../../views/utils';
import { Challenges } from '../../../../../../editor/types/Experiments';
import challengeSettings from '../../../../settingsParams';

import { classes, st } from './StepsNavigation.st.css';

export interface IStepNavigation {
  stepId: string;
  sectionId?: string;
  onChangeEntity(entity: IFollowingEntity): Promise<void>;
}

export const StepsNavigation: FCWithChildren<IStepNavigation> = (
  props: IStepNavigation,
) => {
  const { stepId, sectionId, onChangeEntity } = props;
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const settings = useSettings();
  const { listParticipantSections = [] } = useSections();
  const { participantSteps } = useParticipantSteps();
  const { isResolveStepRequestInProgress } = useResolveStep();
  const { experiments } = useExperiments();
  const previousEntity = utils.getFollowingEntity(
    stepId,
    sectionId || null,
    {
      listParticipantSections,
      participantSteps,
    },
    'previous',
  );
  const nextEntity = utils.getFollowingEntity(
    stepId,
    sectionId || null,
    {
      listParticipantSections,
      participantSteps,
    },
    'next',
  );

  if (!experiments.enabled(Challenges.enableUndoButton)) {
    return null;
  }

  const PreviousButton =
    previousEntity.type !== ChallengeEntityType.VOID ? (
      <SidebarLayoutButton
        dataHook={sidebarLayoutDataHooks.stepPreviousBtn()}
        disabled={isResolveStepRequestInProgress}
        size={isMobile ? ButtonSIZE.small : ButtonSIZE.medium}
        prefixIcon={<ChevronLeftSmall />}
        moveFocus={false}
        isTextButton={true}
        onClick={async () => {
          await onChangeEntity(previousEntity);
        }}
      >
        {settings.get(challengeSettings.prevButtonTxt) as string}
      </SidebarLayoutButton>
    ) : null;

  const NextButton =
    nextEntity.type !== ChallengeEntityType.VOID ? (
      <SidebarLayoutButton
        dataHook={sidebarLayoutDataHooks.stepNextBtn()}
        disabled={isResolveStepRequestInProgress}
        size={isMobile ? ButtonSIZE.small : ButtonSIZE.medium}
        suffixIcon={<ChevronRightSmall />}
        moveFocus={false}
        isTextButton={true}
        onClick={async () => {
          await onChangeEntity(nextEntity);
        }}
      >
        {settings.get(challengeSettings.nextButtonTxt) as string}
      </SidebarLayoutButton>
    ) : null;

  return (
    <Box
      gap={6}
      direction="row"
      alignItems="center"
      className={st(classes.root, {
        mobile: isMobile,
        align: settings.get(challengeSettings.sidebarLayoutTextAlignment),
      })}
    >
      {PreviousButton}
      {NextButton}
    </Box>
  );
};
